<template>
	<main id="cgv_dasboard">
		<mainNav />
		<div class="wrapper">
			<div class="top">
				<div class="count">
					Bonjour <span>{{ $store.state.user.fields.prenom }}</span>
				</div>
				<h2>Vos clients</h2>
			</div>
			<div class="content">
				<div class="begin" v-if="$store.state.user.fields.role != 'atc' && $store.state.current_secteur_client.length == 0">
					<i class="fal fa-long-arrow-left"></i>Choisissez d'abord <strong>un secteur commercial</strong> sur la gauche de l'écran
				</div>
				<div class="zone">
					<div class="toolbar">
						<div class="flex">
							<div class="search">
								<input type="text" id="search" v-model="searchTerm" placeholder="Société, secteur, ..." />
								<i class="fal fa-search"></i>
							</div>
							<select v-model="statut">
								<option value="">Tous les statuts</option>
								<option value="A envoyer">A envoyer</option>
								<option value="En cours de signature">En cours de signature</option>
								<option value="Signé">Signé</option>
								<option value="Refusé">Refusé</option>
							</select>
						</div>
						
						<div class="buttons">
							<input type="file" id="file" class="ajouter" @change="createFromCSV" accept=".csv" />
							<label for="file">Importer un fichier</label>
							<button class="button" @click="showModalCreate = true">Ajouter un client</button>
						</div>
					</div>
					<div class="liste_clients" >
						<div class="en-tete">
							
							<div class="nom">Société</div>
							<div class="numero">Numéro client</div>
							<div class="statut">Statut</div>
							<div class="signataire">Signataire</div>
							<div class="actions">Actions</div>
						</div>
						<div v-if="filteredClients.length > 0">
							<div class="case" v-for="client in filteredClients" :key="client.id" >
								
								<div class="nom" @click="toggleClient(client.id)">
									{{ client.fields.societe }}
								</div>
								<div class="numero">
									{{ client.fields.numero }}
								</div>
								<div class="statut">
									<span :class="`${sanitizeTitle(client.fields.statut)} bulle`">{{
										client.fields.statut == "En cours de signature" ? "En cours" : client.fields.statut
									}}</span>
								</div>
								<div class="signataire">{{ client.fields.prenom_signataire }} {{ client.fields.nom_signataire }}</div>
								<div class="actions">
									<button v-if="client.fields.statut === 'A envoyer' && !client.fields.isSending" @click="sendCGVtoClient(client.id)">Envoyer pour signature</button>
									<button
										v-if="client.fields.statut === 'En cours de signature' && !client.fields.isSending"
										@click="checkSignature(client.id)"
									>
										Vérifier le statut
									</button>
									<a v-if="client.fields.statut === 'Signé'" :href="client.fields.url_pdf_signe[0].url" target="_blank">Télécharger la preuve</a>
									<div class="loading" v-if="client.isSending === true">
										<i class="fal fa-spin fa-spinner"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="no-clients" v-else>Aucun client à afficher</div>
					</div>
				</div>
			</div>
		</div>
		<aside
			class="addClient"
			:style="`${showModalCreate ? 'opacity: 1; transform: translateY(0); pointer-events: auto;' : 'opacity: 0; transform: translateX(30%); pointer-events: none;'}`"
		>
			<div class="wrapper">
				<h3>Ajouter un client</h3>
				<div class="close" @click="showModalCreate = false">
					<i class="fal fa-times"></i>
				</div>
				<form @submit.prevent="createClient">
					<div class="form-group">
						<label for="numero">Numéro client</label>
						<input type="text" id="numero" v-model="new_client.numero" required />
					</div>
					<div class="form-group">
						<label for="secteur">Secteur</label>
						<input type="text" id="secteur" v-model="new_client.secteur" required />
					</div>
					<div class="form-group">
						<label for="societe">Société</label>
						<input type="text" id="societe" v-model="new_client.societe" required />
					</div>
					<div class="form-group">
						<label for="email_signataire">Email signataire</label>
						<input type="email" id="email_signataire" v-model="new_client.email_signataire" required />
					</div>
					<div class="form-group">
						<label for="numero_signataire">Téléphone signataire</label>
						<input type="tel" id="numero_signataire" placeholder="+33" v-model="new_client.numero_signataire" required />
					</div>
					<small>Ce numéro sera utilisé pour la signature. <br />De la forme +33601020304</small>
					<div class="form-group">
						<label for="prenom_signataire">Prénom signataire</label>
						<input type="text" id="prenom_signataire" v-model="new_client.prenom_signataire" required />
					</div>
					<div class="form-group">
						<label for="nom_signataire">Nom signataire</label>
						<input type="text" id="nom_signataire" v-model="new_client.nom_signataire" required />
					</div>
					<div class="check">
						<van-checkbox checked-color="#558b2f" v-model="envoyer_apres_ajout">Envoyer les CGV après l'ajout</van-checkbox>
					</div>
					
					<div class="submit">
						<button type="submit">Créer le client</button>
					</div>
				</form>
			</div>
		</aside>
	</main>
</template>
<script>
import mainNav from "../components/mainNav";
import { Notify, Checkbox } from "vant";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
export default {
	name: "CGV",
	components: { mainNav },
	data() {
		return {
			searchTerm: "",
			statut: "",
			envoyer_apres_ajout: false,
			new_client: {
				numero: "0123456789",
				secteur: this.$store.state.user.fields.secteur,
				societe: "Test",
				email_signataire: "matt88120@gmail.com",
				numero_signataire: "+33630745526",
				prenom_signataire: "Matthieu",
				nom_signataire: "Andre",
				statut: "A envoyer",
				utilisateurs: this.$store.state.user.id
			},
			checkedClients: [],
			showModalCreate: false,
		};
	},
	methods: {
		...mapActions({
			beginSignatureCGV: "beginSignatureCGV",
			CGV_createClient: "CGV_createClient"
		}),
		sanitizeTitle: function(title) {
			var slug = "";
			var titleLower = title.toLowerCase();
			slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
			slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
			slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
			slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
			slug = slug.replace(/đ/gi, "d");
			slug = slug.replace(/\s*$/g, "");
			slug = slug.replace(/\s+/g, "-");
			return slug;
    		},
		async createClient() {
			
			// verify if the new_client.numero_signataire is correctly formed (+33601020304)
			// si le numero commence par 06 ou 07, mettre +33
			if (this.new_client.numero_signataire.length > 0 && this.new_client.numero_signataire.substring(0, 3) !== "+33") {
				//supprimer le premier 0 si il y en a un
				if (this.new_client.telephone_signataire.substring(0, 1) === "0") {
					this.new_client.numero_signataire = this.new_client.numero_signataire.substring(1);
				}
				this.new_client.numero_signataire = "+33" + this.new_client.numero_signataire;
			}
			
			// verify if the email is correctly formed
			let email = this.new_client.email_signataire.toLowerCase();
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
			if (!re.test(email)) {
				Notify({ type: "danger", message: "L'email n'est pas valide" });
				this.new_client.email_signataire = "";
				return;
			}
			
			// association de l'utilisateur par défaut
			let utilisateur_attribue = this.$store.state.users.find(user => user.fields.secteur == this.new_client.secteur);
			if (utilisateur_attribue) {
				this.new_client.utilisateurs = [utilisateur_attribue.id];
			}
			
			this.new_client.utilisateurs = [this.$store.state.user.id];
			const res = await this.CGV_createClient(this.new_client);
			this.showModalCreate = false;
			if(this.envoyer_apres_ajout) {
				this.sendCGVtoClient(res.id);
			} else {
				this.refreshClients();
				Notify({ type: "success", message: "Le client a bien été ajouté" });
			}
		},
		async createFromCSV(e) {
			let file = e.target.files[0];
			let reader = new FileReader();
			reader.readAsText(file);
			reader.onload = async(e) => {
				let csv = e.target.result;
				let clients = csv.split("\n");
				// delete first line
				clients.shift();
				for (const client of clients) {
					let content = client.split(";");
					let fields = {
						numero: content[0],
						secteur: content[1],
						societe: content[2],
						nom_signataire: content[3],
						prenom_signataire: content[4],
						email_signataire: content[5],
						numero_signataire: content[6],
						statut: content[7]
					};
					// association de l'utilisateur par défaut
					let utilisateur_attribue = this.$store.state.users.find(user => user.fields.secteur == this.new_client.secteur);
					if (utilisateur_attribue) {
						fields.utilisateurs = [utilisateur_attribue.id];
					}
					await this.CGV_createClient(fields);
				}
				Notify({ type: "success", message: "Les clients ont bien été ajoutés" });
			}
			
		},
		toggleAllClients(e) {
			if(e.target.checked) {
				this.clients.forEach(client => {
					this.checkedClients.push(client.id);
				});
			} else {
				this.checkedClients = [];
			}
		},
		async sendCGVtoClient(id) {
			const base_api_url = "https://api.yousign.app/v3";
			const apiKey = `T5gZhKWiCHtebTRuF6M7N2YumkuJjMDv`; // When it is ready for production, replace it with a production API key
			const headers = {
				Authorization: "Bearer T5gZhKWiCHtebTRuF6M7N2YumkuJjMDv",
				"Content-Type": "application/json"
			};
			let client = this.clients.find(el => el.id == id);
			let response, requestBodyPayload;
			if (!client) {
				return;
			}
			try {
				// récupération du client
				if (!client.isSending) {
					client.isSending = false;
				}
				client.isSending = true;

				requestBodyPayload = {
					name: `CGV Viessmann 2025 - ${client.fields.societe}`,
					delivery_mode: "email",
					custom_experience_id: "d09f6fed-1f8d-4cd5-adc9-1bcc0cd9e5df",
					timezone: "Europe/Paris"
				};

				response = await fetch(`${base_api_url}/signature_requests`, {
					method: "POST",
					headers,
					body: JSON.stringify(requestBodyPayload)
				});

				const signatureRequest = await response.json();

				// Ajout du document
				const form = new FormData();
				const pdfBlob = await fetch("https://dev-serveur.fr/mailjet/viessmann/cgv.pdf").then(response => response.blob());
				form.append("file", pdfBlob, `cgv_viessman_2025_${client.fields.numero}.pdf`);
				form.append("nature", "signable_document");

				response = await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/documents`, {
					method: "POST",
					headers: {
						authorization: `Bearer ${apiKey}`
					},
					body: form
				});

				const document = await response.json();

				// Ajout du signataire
				requestBodyPayload = {
					info: {
						first_name: client.fields.prenom_signataire.trim(),
						last_name: client.fields.nom_signataire.trim(),
						email: client.fields.email_signataire.trim(),
						phone_number: client.fields.numero_signataire.trim(),
						locale: "fr"
					},
					signature_authentication_mode: "otp_sms",
					signature_level: "electronic_signature",
					fields: [
						{
							document_id: document.id,
							type: "signature",
							height: 60,
							width: 212,
							page: 6,
							x: 341,
							y: 20
						}
					]
				};

				await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/signers`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
						authorization: `Bearer ${apiKey}`
					},
					body: JSON.stringify(requestBodyPayload)
				});

				await fetch(`${base_api_url}/signature_requests/${signatureRequest.id}/activate`, {
					method: "POST",
					headers: {
						"Content-type": "application/json",
						authorization: `Bearer ${apiKey}`
					}
				});

				this.beginSignatureCGV({
					client: client,
					id_procedure_signature: signatureRequest.id,
					lien_signature: signatureRequest.url,
				}).then(() => {
					client.isSending = false;
				})

			} catch (err) {
				console.log(err);
				Notify({ type: "danger", message: "Une erreur est survenue lors de l'envoi du mail" });
				client.isSending = false;
				//alert(err);
			}
		},
		refreshClients() {
			this.$store.dispatch("CGV_getClients");
		},
		checkSignature(id) {
			let client = this.clients.find(el => el.id == id);
			this.$store
				.dispatch("checkSignatureV3", client.fields.id_procedure_signature)
				.then(res => {
					if (res.status === "ongoing") {
						Notify({ type: "warning", message: "Le document n'a pas encore été signé." });
						this.$store.commit("updateClientCGVStatus", {
							client: client,
							value: "En cours de signature"
						});
					}
					if (res.status === "done") {
						Notify({ type: "success", message: "Bravo ! La signature est finalisée." });
						this.$store.commit("updateClientCGVStatus", {
							client: client,
							value: "Signé"
						});
						this.$store.dispatch("downloadSignedDocumentCGV", {
							procedure: client.fields.id_procedure_signature,
							client: client
						});
						this.refreshClients();
					}
					if (res.status === "expired" || res.status === "canceled" || res.status === "rejected" || res.status === "declined") {
						Notify({ type: "danger", message: "La signature a échouée. Le contrat est repassé en brouillon pour que vous puissiez relancer la procédure." });
						this.$store.commit("updateClientCGVStatus", {
							client: client,
							value: "A envoyer"
						});	
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		toggleClient(id) {
			let found = this.checkedClients.findIndex(el => el == id);
			if (found != -1) {
				this.checkedClients.splice(found, 1);
			} else {
				this.checkedClients.push(id);
			}
		}
	},
	computed: {
		...mapState({
			clients: "CGV_clients"
		}),
		filteredClients() {
			let temp = this.clients;
			if (this.statut.length > 0) {
				temp = this.clients.filter(client => client.fields.statut == this.statut);
			} 
			if(this.searchTerm.length > 2) {
				temp = temp.filter(
					client =>
						client.fields.societe.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
						client.fields.numero.toLowerCase().includes(this.searchTerm.toLowerCase())
				);
			}
			return temp;
		}
	},
	mounted() {

		if (this.$store.state.user.fields.role != "atc") {
			// ne rien faire pour les clients
		} else {
			this.$store.dispatch("CGV_getClients");
		}
	},
};
</script>
<style lang="scss">
#cgv_dasboard {
	margin: 0;
	padding: 0;
	display: flex;
	background: white;
	min-height: 100vh;
	@media screen and (max-width: $screen-phone) {
		padding-top: 50px;
		min-height: calc(100vh - 50px);
	}
	h2 {
		font-size: 56px;
		line-height: 50px;
		font-weight: 500;
	}
	h4 {
		font-size: 18px;
		font-weight: 300;
		margin-bottom: 40px;
		margin-top: 10px;
	}
	.wrapper {
		width: 100%;
		display: flex;
		max-width: 1400px;
		margin: 0 auto;
		padding: 30px;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		gap: 40px;
	}
	.top {
		.count {
			font-size: 18px;
			line-height: 14px;
			font-weight: 300;
			flex: 0 0 auto;
			color: slategray;
			padding-bottom: 10px;
		}
	}
	.content {
		width: 100%;
		.baseline {
			font-size: 14px;
			font-weight: medium;
			color: grey;
			font-style: italic;
		}
		.toolbar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: solid 1px rgba($bleu, 0.1);
			padding-bottom: 20px;
			margin-bottom: 10px;
			.flex {
				display: flex;
				gap: 10px;
				select {
					border: solid 1px rgba($bleu, 0.1);
					background: white;
					padding: 6px 16px;
					border-radius: 4px;
					color: darken($fond, 30%);
				}
			}
			.search {
				background: white;
				padding: 6px 16px;
				border-radius: 4px;
				border: solid 1px rgba($bleu, 0.1);
				display: flex;
				position: relative;
				align-items: center;
				input[type="text"] {
					background: transparent;
					border: none;
					outline: none;
					font-size: 14px;
					font-weight: 500;
					color: darken($fond, 30%);
				}
			}
			.buttons {
				display: flex;
				align-items: center;
				gap: 10px;
				.ajouter {
					background: transparent;
					border: none;
					outline: none;
					color: $bleu;
					font-size: 14px;
					padding: 3px 10px;
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover {
						color: darken($bleu, 10%);
					}
				}
				.button {
					background: $bleu;
					border: darken($bleu, 5%) solid 1px;
					padding: 5px 10px;
					border-radius: 4px;
					color: white;
					transition: all 0.3s ease;
					cursor: pointer;
					&:hover {
						background: darken($bleu, 10%);
						transform: translateY(2px);
					}
				}
			}
		}
		.begin {
			padding: 20px;
			display: flex;
			flex-direction: column;
			font-size: 16px;
			font-weight: 300;
			line-height: 26px;
			background: rgba($bleu, 0.03);
			max-width: 360px;
			border-radius: 30px;
			padding: 30px;
			i,
			svg {
				margin-bottom: 20px;
				font-size: 26px;
			}
			strong {
				font-weight: 500;
				font-size: 18px;
				color: $bleu;
			}
		}
		.zone {
			transition: all 0.3s ease;
			border-radius: 20px;
			position: relative;
			padding: 30px;
			background: rgba($bleu, 0.03);
			border-radius: 30px;
			margin-bottom: 30px;
			margin-top: 40px;
			line-height: 24px;
			font-size: 16px;
			&:hover {
				box-shadow: 0 0 50px rgba($fond, 0.4);
			}
			h3 {
				text-align: left;
				font-weight: 500;
				font-size: 20px;
				text-transform: none;
				margin-bottom: 10px;
				margin-top: 10px;
				color: $bleu;
			}
		}
		.liste_clients {
			display: flex;
			flex-direction: column;
			.en-tete {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				padding: 8px;
				font-size: 16px;
				font-weight: 500;
				.checkbox {
					flex: 0 0 auto;
					width: 25px;
					display: none;
					display: flex;
					align-items: center;
					input[type="checkbox"] {
						width: 16px;
						height: 16px;
						margin: 0;
					}
				}
				.nom {
					flex: 1;
				}
				.numero {
					flex: 0 0 auto;
					width: 130px;
				}
				.statut {
					flex: 0 0 auto;
					width: 100px;
					text-align: right;
				}
				.signataire {
					flex: 0 0 auto;
					padding: 0 10px;
					width: 130px;
					text-align: right;
				}
				.actions {
					padding-left: 10px;
					flex: 0 0 auto;
					width: 180px;
					display: flex;
					justify-content: flex-end;
				}
			}
			.case {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				padding: 10px;
				.checkbox {
					flex: 0 0 auto;
					display: none;
					width: 25px;
					display: flex;
					align-items: center;
					input[type="checkbox"] {
						width: 16px;
						height: 16px;
						margin: 0;
					}
				}
				.nom {
					flex: 1;
					font-size: 15px;
					font-weight: 500;
				}
				.numero {
					flex: 0 0 auto;
					width: 130px;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 14px;
					font-weight: 300;
				}
				.signataire {
					flex: 0 0 auto;
					padding: 0 10px;
					width: 130px;
					text-align: right;
					font-size: 14px;
					font-weight: 300;
				}
				.actions {
					padding-left: 10px;
					flex: 0 0 auto;
					width: 180px;
					display: flex;
					align-items: center;
					gap: 6px;
					justify-content: flex-end;
					button, a {
						background: rgba($vert, 0.1);
						color: $vert;
						border: none;
						outline: none;
						font-size: 12px;
						padding: 3px 6px;
						border-radius: 4px;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: rgba($vert, 0.2);
							transform: translateY(2px);
						}
					}
				}
				.statut {
					flex: 0 0 auto;
					width: 100px;
					font-size: 12px;
					font-weight: 500;
					text-align: right;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					span.bulle {
						color: white;
						background: $fond;
						padding: 3px 6px;
						border-radius: 4px;
						line-height: 1;
						display: inline-block;
						&.en-cours-de-signature {
							background: $bleu;
						}
						&.signe {
							background: $vert;
						}
						&.refuse {
							background: $rouge;
						}
					}
					span.date {

					}

					
				}
				&:nth-child(even) {
					background: white;
				}
				&:hover {
					background: white;
					box-shadow: 0px 4px 6px rgba($bleu, 0.05);
				}
			}
			.no-clients {
				padding: 50px;
				font-size: 16px;
				text-align: center;
				font-weight: 300;
				color: darken($fond, 20%);
			}
		}
	}
	.addClient {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		box-shadow: 0px 0px 40px rgba($fond, 0.3);
		border-left: solid 2px rgba($bleu, 0.1);
		z-index: 8888;
		width: 400px;
		max-width: 90%;
		transition: all 0.3s ease;
		background: linear-gradient(to top, lighten($fond, 19%), white);
		.wrapper {
			width: 100%;
			height: 100%;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			.close {
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
				color: $fond;
				padding: 10px;
				transition: all 0.3s ease;
				&:hover {
					color: $bleufonce;
					transform: translateY(2px);
				}
			}
			form {
				width: 100%;
			}
			.submit {
				display: flex;
				justify-content: flex-end;
				button {
					background: $bleufonce;
					color: white;
					padding: 7px 10px;
					border-radius: 4px;
					border: none;
					outline: none;
					transition: all .3s ease;
					font-size: 15px;
					font-weight: 500;
					cursor: pointer;
					&:hover {
						background: darken($bleu, 20%);
						transform: translateY(2px);
					}
				}
			}
			.check {
				margin-bottom: 30px;
				color: $fond;
				.van-checkbox {
					.van-checkbox__label {
						color: darken($fond, 20%) !important;
						font-size: 14px !important;
					}
				}
				
			}
			.form-group {
				display: flex;
				position: relative;
				width: 100%;
				flex-direction: column;
				border: solid 1px rgba($bleu, 0.2);
				background: rgba($bleu, 0.05);
				border-radius: 4px;
				padding: 10px 10px 5px 10px;
				margin-bottom: 20px;
				label {
					color: $bleufonce;
					font-size: 11px;
					font-weight: 500;
					position: absolute;
					top: 0;
					transform: translateY(-50%);
					border: solid 1px rgba($bleu, 0.2);
					background: lighten($bleu, 98%);
					padding: 2px 6px;
					border-radius: 4px;
				}
				input, select {
					outline: none;
					border: none;
					height: 35px;
					font-weight: 500;
					background: transparent;
				}
				&+small {
					color: darken($fond, 10%);
					font-size: 13px;
					line-height: 1.3;
					display: block;
					font-style: italic;
					transform: translateY(-15px);
					padding-left: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}
}

</style>
